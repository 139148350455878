import React from 'react';
import { PageProps, graphql } from "gatsby"
import { usageAcknowledgment } from '@amanda-mitchell/biblia-api'
import { copyrightNotifications  } from '@amanda-mitchell/esv-api'

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

type Data = {
  site: {
    siteMetadata: {
      title: string;
    };
  };
}

const BlogIndex = ({ data, location }: PageProps<Data>) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Legal stuff" />
			<header>
				<h1>Legal stuff</h1>
			</header>
      <p>Here are a few notifications that I&rsquo;m required to provide:</p>
			<p><i>Of course</i> links to Amazon are affiliate links.  As an Amazon Associate, I earn from qualifying purchases.</p>
			<p><span dangerouslySetInnerHTML={{ __html: usageAcknowledgment }} /></p>
			<p>{copyrightNotifications.markedQuotations}</p>
      <Bio />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
